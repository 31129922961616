import { useStaticQuery } from 'gatsby';

var useLikesQuery = function useLikesQuery() {
  // query "id" and "likes"
  var data = useStaticQuery("389653385"); // formatted query data into { id: likes }

  var likesObj = {};
  data.allSanityBlog.edges.forEach(function (_ref) {
    var blog = _ref.node;
    likesObj[blog._id] = blog.likes;
  });
  return likesObj;
};

export default useLikesQuery;