import { useState, useEffect } from 'react';

var useHasMounted = function useHasMounted() {
  var _useState = useState(false),
      hasMounted = _useState[0],
      setHasMounted = _useState[1];

  useEffect(function () {
    setHasMounted(true);
  }, []);
  return hasMounted;
};

export default useHasMounted;