import { useEffect, useState, useCallback } from 'react';

var useScroll = function useScroll() {
  // check scroll direction
  var _useState = useState(true),
      isShow = _useState[0],
      setIsShow = _useState[1];

  var _useState2 = useState(0),
      scrollYPosition = _useState2[0],
      setScrollYPosition = _useState2[1];

  var scrollDownHideUpShow = function scrollDownHideUpShow() {
    var scrollEventHandler = useCallback(function () {
      // Store current scrollY position
      setScrollYPosition(window.pageYOffset); // Show hamburger icon when scroll up
      // Hide hamburger icon when scroll down

      window.scrollY < scrollYPosition ? setIsShow(false) : setIsShow(true);
    }, [scrollYPosition]);
    useEffect(function () {
      window.addEventListener('scroll', scrollEventHandler);
      return function () {
        return window.removeEventListener('scroll', scrollEventHandler);
      };
    }, [scrollEventHandler]);
    return {
      isShow: isShow
    };
  };

  return {
    isShow: isShow,
    setIsShow: setIsShow,
    scrollDownHideUpShow: scrollDownHideUpShow
  };
};

export default useScroll;