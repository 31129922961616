import { useState } from 'react';

var useSideDrawer = function useSideDrawer() {
  var _useState = useState(false),
      drawerIsOpen = _useState[0],
      setDrawerIsOpen = _useState[1];

  var openDrawerHandler = function openDrawerHandler() {
    setDrawerIsOpen(true);
  };

  var closeDrawerHandler = function closeDrawerHandler() {
    setDrawerIsOpen(false);
  };

  return {
    drawerIsOpen: drawerIsOpen,
    openDrawerHandler: openDrawerHandler,
    closeDrawerHandler: closeDrawerHandler
  };
};

export default useSideDrawer;