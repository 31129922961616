module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The official portfolio website of Kento Takeuchi","short_name":"Portfolio","description":"Welcome to the official portfolio website of Kento Takeuchi","start_url":"/","lang":"en","background_color":"rgb(0, 0, 0)","theme_color":"rgb(255, 255, 255)","display":"minimal-ui","include_favicon":false,"icon":"src/asset/images/apple.png","icons":[{"src":"icons/icon-144x144.png","sizes":"144x144","type":"image/png"}],"localize":[{"name":"竹内健人ポートフォリオウェブサイト","short_name":"ポートフォリオ","description":"竹内健人ポートフォリオウェブサイトへようこそ","start_url":"/ja/","lang":"ja"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"03c3dfea35901d2c28fbc1c69c9e7e54"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
